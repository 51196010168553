<template>
    <div>
        <div class="bg-durance-pink position-fixed top-0 strip"></div>
        <v-app-bar
            :density="$vuetify.display.lgAndUp ? 'prominent' : 'default'"
            shrink-on-scroll
            name="app-bar"
            class="bg-durance-grey app-bar"
            elevation="0"
            :height="$vuetify.display.lgAndUp ? 40 : 60"
            :extension-height="$vuetify.display.lgAndUp ? 27 : 50"
            :class="{ shrink, 'mt-5' : !pinkStrip, 'mt-2': pinkStrip  && $vuetify.display.mdAndDown }"
            v-scroll="onScroll"
        >
            <template v-slot:extension>
                <div ref="appBar" id="extension-wrap" class="w-100 h-100" :class="{'grid-bar': shrink}">
                    <div v-if="shrink" class="d-flex flex-column align-self-center">
                        <Link href="/">
                            <v-img :src="logo.src" :alt="logo.alt" width="110px" height="40px" />
                        </Link>
                    </div>
                    <div class="d-flex align-center justify-center align-self-stretch">
                        <v-slide-x-transition leave-absolute>
                            <search
                                v-if="(shrink && searchActive) || $vuetify.display.smAndDown"
                                :mobile="false"
                                show
                                :class="{
                                    'px-4': $vuetify.display.smAndDown,
                                }"
                            ></search>
                            <horizontal-menu
                                v-else
                                :logo="logo"
                                :items="menu"
                                :shrink="pinkStrip"
                            ></horizontal-menu>
                        </v-slide-x-transition>
                    </div>
                    <div v-if="shrink" class="d-flex align-center justify-end">
                        <v-btn
                            v-if="$vuetify.display.mdAndUp"
                            class="head-cart-item head-cart-item__shrink"
                            :class="$vuetify.display.mdAndDown ? 'mr-2' : ''"
                            @click="searchActive = !searchActive"
                            icon
                        >
                            <transition name="fab-transition">
                                <v-icon
                                    :icon="searchActive ? mdilCancel : mdilMagnify"
                                    class="position-absolute"
                                    :key="searchActive"
                                />
                            </transition>
                        </v-btn>
                        <transition name="fab-transition">
                            <Link
                                v-if="comparatorStore.data?.count >= 0"
                                :href="route('comparator')"
                                style="color: inherit"
                            >
                                <v-btn
                                    v-if="
                                        !$page.props.comparator.saved ||
                                        $page.props.comparator.saved.length === 0 ||
                                        $vuetify.display.smAndDown
                                    "
                                    class="head-cart-item head-cart-item__shrink"
                                    :class="$vuetify.display.mdAndDown ? 'mr-2' : ''"
                                    icon
                                >
                                    <v-badge
                                        offset-x="-5"
                                        color="cis-accent-color"
                                        :model-value="comparatorStore.data > 0"
                                        :content="comparatorStore.data.count"
                                    >
                                        <v-icon :icon="mdilRepeat" />
                                    </v-badge>
                                </v-btn>
                                <v-btn
                                    v-else
                                    class="head-cart-item head-cart-item__shrink"
                                    :class="$vuetify.display.mdAndDown ? 'mr-2' : ''"
                                >
                                    <template #default>
                                        <v-badge
                                            offset-x="-5"
                                            color="cis-accent-color"
                                            :model-value="comparatorStore.data > 0"
                                            :content="comparatorStore.data.count"
                                        >
                                            <v-icon :icon="mdilRepeat" />
                                        </v-badge>
                                    </template>
                                    <template
                                        #prepend
                                        v-if="
                                            $page.props.comparator.saved && $vuetify.display.mdAndUp
                                        "
                                    >
                                        {{ $page.props.comparator.saved.name }}
                                    </template>
                                </v-btn>
                            </Link>
                        </transition>
                        <transition name="fab-transition">
                            <v-btn
                                v-if="shrink"
                                class="head-cart-item head-cart-item__shrink"
                                :class="$vuetify.display.mdAndDown ? 'mr-2' : ''"
                                icon
                                @click.stop="emit('closeRightDrawer')"
                            >
                                <v-badge
                                    left
                                    color="cis-accent-color"
                                    :model-value="cartItemsLength > 0"
                                    :content="cartItemsLength"
                                >
                                    <v-icon :icon="mdilCart" />
                                </v-badge>
                            </v-btn>
                        </transition>
                        <transition name="fab-transition">
                            <login-btn
                                v-if="shrink"
                                id="bar"
                                :open-login="$vuetify.display.lgAndUp && $page.props.openLogin"
                            ></login-btn>
                        </transition>
                    </div>
                </div>
            </template>
            <v-app-bar-nav-icon
                @click.stop="emit('closeLeftDrawer')"
                v-show="$vuetify.display.smAndDown"
            />
            <v-app-bar-title :class="$vuetify.display.lgAndUp ? 'ml-3' : ''">
                <div class="justify-start">
                    <Link
                        v-if="$vuetify.display.xs"
                        href="/"
                        class="text-decoration-none d-inline-flex pl-1"
                        style="flex: 0 1 140px"
                    >
                        <v-img :src="logo_alt.src" :alt="logo_alt.alt" width="5em" />
                    </Link>
                    <search
                        v-if="$vuetify.display.mdAndUp"
                        :mobile="false"
                        class="position-absolute"
                        style="width: 300px"
                        show
                    ></search>
                </div>
                <div class="justify-center">
                    <Link
                        v-if="$vuetify.display.smAndUp"
                        href="/"
                        class="text-decoration-none d-inline-flex pl-1"
                        style="flex: 0 1 140px; margin-top: 8px"
                    >
                        <v-img :src="logo_alt.src" :alt="logo_alt.alt" width="4em" />
                    </Link>
                </div>
                <div class="justify-end">
                    <Link
                        v-if="comparatorStore.data.count >= 0"
                        :href="route('comparator')"
                        style="color: inherit"
                    >
                        <v-btn
                            v-if="
                                !$page.props.comparator.saved ||
                                $page.props.comparator.saved.length === 0 ||
                                $vuetify.display.smAndDown
                            "
                            class="head-cart-item head-cart-item__shrink"
                            :class="$vuetify.display.mdAndDown ? 'mr-2' : ''"
                            icon
                        >
                            <v-badge
                                offset-x="-5"
                                color="cis-accent-color"
                                :model-value="comparatorStore.data.count > 0"
                                :content="comparatorStore.data.count"
                            >
                                <v-icon :icon="mdilRepeat" />
                            </v-badge>
                        </v-btn>
                        <v-btn
                            v-else
                            class="head-cart-item head-cart-item__shrink"
                            :class="$vuetify.display.mdAndDown ? 'mr-2' : ''"
                        >
                            <template #default>
                                <v-badge
                                    offset-x="-5"
                                    color="cis-accent-color"
                                    :model-value="comparatorStore.data.count > 0"
                                    :content="comparatorStore.data.count"
                                >
                                    <v-icon :icon="mdilRepeat" />
                                </v-badge>
                            </template>
                            <template
                                #prepend
                                v-if="$page.props.comparator.saved && $vuetify.display.mdAndUp"
                            >
                                {{ $page.props.comparator.saved.name }}
                            </template>
                        </v-btn>
                    </Link>
                    <v-btn
                        class="head-cart-item"
                        :class="$vuetify.display.mdAndDown ? 'mr-2' : ''"
                        icon
                        @click.stop="emit('closeRightDrawer')"
                    >
                        <v-badge
                            left
                            color="cis-accent-color"
                            :model-value="cartItemsLength > 0"
                            :content="cartItemsLength"
                        >
                            <v-icon :icon="mdilCart" />
                        </v-badge>
                    </v-btn>
                    <login-btn
                        id="bar"
                        :open-login="$vuetify.display.lgAndUp && $page.props.openLogin"
                    ></login-btn>
                </div>
            </v-app-bar-title>
        </v-app-bar>
    </div>
</template>

<script setup>
import { computed, ref, inject } from 'vue';
import { Link, usePage } from '@inertiajs/vue3';
import { useDisplay } from 'vuetify';
import LoginBtn from './LoginBtn.vue';
import 'swiper/css';
import 'swiper/css/navigation';
import { useCartStore } from '../../modules/eshop/store/cart';
import { useComparatorStore } from '../../modules/eshop/store/comparator.js';
import pinia from '../../plugins/pinia';
import Search from './Search.vue';
import { urlWithParameter } from '../../utils/image.js';
import HorizontalMenu from './HorizontalMenu.vue';
import { mdilCancel, mdilCart, mdilMagnify, mdilRepeat } from '@mdi/light-js';

const props = defineProps({
    menu: Array,
    menuModel: Array,
    activeMenu: Object
})

const cartStore = useCartStore(pinia);
const comparatorStore = useComparatorStore(pinia);

const route = inject('route');
const emit = defineEmits(['closeLeftDrawer', 'closeRightDrawer']);
const appBar = ref(null);
const searchActive = ref(false);

const { lgAndUp } = useDisplay();
const cartItemsLength = computed(() => cartStore.cartItems.length.toString() || '0');
const shrink = ref(false);
const pinkStrip = ref(false);
const logo = computed(() => {
    const obj = {
        src: '/logo.png?max-width=200',
        alt: 'Logo',
    };
    const settingsLogo = usePage().props.logo;
    if (settingsLogo) {
        obj.src = urlWithParameter(settingsLogo.url, 'max-width=200');
        if (settingsLogo.alt) {
            obj.alt = settingsLogo.alt;
        }
    }
    return obj;
});
const logo_alt = computed(() => {
    return {
        src: '/durance_white.svg?max-width=200',
        alt: 'Logo',
    };
});
const onScroll = () => {
    if (!import.meta.env.SSR && Math.round(window.scrollY) >= 64) {
        if (lgAndUp.value) {
            shrink.value = true;
        }
        pinkStrip.value = true;
        return;
    }
    shrink.value = false;
    pinkStrip.value = false;
};
</script>

<style scoped lang="scss">
@use 'vuetify/lib/styles/settings/colors' as *;
@use 'vuetify/lib/components/VGrid/mixins' as *;
@use '/resources/css/variables' as v;
@use 'vuetify/lib/styles/settings/variables' as *;

.shrink {
    :deep(.v-toolbar__content) {
        height: 0 !important;
        opacity: 0;

        .v-toolbar-title {
            padding-bottom: 0 !important;
        }
    }
}

.main-menu {
    margin-bottom: 19px;
}

.v-app-bar {
    :deep(.v-toolbar__content) {
        @include make-container-max-widths;

        & {
            max-width: v.$container-max-width !important;
            margin-inline: auto;
            transition-property: height, width, transform, max-width, left, right, top, bottom,
                box-shadow, opacity;
        }
    }

    :deep(.v-toolbar-title) {
        height: 100%;
    }

    :deep(.v-toolbar-title__placeholder) {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;

        & > div {
            display: flex;
            align-items: center;
        }
    }

    :deep(.v-toolbar__extension) {
        display: flex;
        justify-content: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        background-color: white;
        color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity)) !important;

        > div {
            @include make-container-max-widths;

            & {
                max-width: v.$container-max-width !important;
                display: grid;
                &.grid-bar {
                    grid-template-columns: auto auto auto;
                }
            }
        }
    }
}

:deep(.v-list-item-title) {
    .warning & {
        color: rgb(var(--v-theme-cis-accent-color)) !important;
    }
}

:deep(a:hover, a:focus, a:active) {
    .v-list-item-title {
        color: rgb(var(--v-theme-cis-accent-color));
    }
}

.strip {
    height: 50px;
    width: 100%;
    z-index: 500;
}

.app-bar {
    margin-top: 0;
    transition: all 0.1s ease-in-out;
}
</style>
