import {defineStore} from "pinia";
import {computed, ref} from "vue";
import {usePage} from "@inertiajs/vue3";

export const useCartStore = defineStore('cart', () => {
    const canCallUpdate = ref(true);
    const cartItems = computed(() => {
        if(usePage().props.finishedOrder) return [];
        return usePage().props.order?.orders_items?.filter((item) => {
            return item.type === 'product';
        }) || []
    })
    const coupon = ref(usePage().props.order?.discount_coupons?.code);
    const setCoupon = (code) => {
        coupon.value = code;
    }
    const couponItem = computed(() => {
        let obj = null;
        const item = usePage().props.order?.orders_items?.filter((item) => {
            return item.type === 'coupon';
        })
        if(item.length){
            obj = {...item[0], name: usePage().props.order.discount_coupons.name}
        }
        return obj;
    })
    const hasCoupon = computed(() => !!usePage().props.order?.discount_coupons?.code)
    return {
        cartItems,
        coupon,
        hasCoupon,
        canCallUpdate,
        couponItem,
        setCoupon
    };
});
