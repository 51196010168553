<template>
    <div class="flex-1-1-100 bg-grey-darken-3 w-100">
        <v-container>
            <div class="text-center py-5">
                <div class="text-h5 mb-3">{{ $t('newsletter.stay_in_touch') }}</div>
                <div>{{ $t('newsletter.sign_in') }}</div>
                <v-form ref="form" id="newsletter-form" @submit.prevent="submit" lazy-validation>
                    <div class="d-inline-flex flex-column" style="width: 500px; max-width: 100%">
                        <v-text-field
                            class="mt-5 px-6"
                            v-model="email"
                            id="newsletter_email"
                            name="newsletter_email"
                            :label="$t('newsletter.email:asterisk', { asterisk: '*' })"
                            variant="outlined"
                            density="compact"
                            rounded="0"
                            :readonly="readonly"
                            flat
                            :color="readonly ? 'cis-accent-color' : undefined"
                            :base-color="readonly ? 'cis-accent-color' : undefined"
                            :error="!!$page.props.errors.newsletter?.email"
                            :error-messages="$page.props.errors.newsletter?.email"
                            counter="50"
                            maxlength="50"
                        >
                        </v-text-field>
                        <v-btn
                            class="align-self-center"
                            type="submit"
                            @click="submit"
                            variant="flat"
                            rounded="0"
                            size="large"
                            style="text-transform: uppercase !important"
                        >
                            {{ $t('newsletter.send_action') }}
                        </v-btn>
                    </div>
                </v-form>
                <div class="mt-4 text-caption">
                    Vložením e-mailu souhlasíte s podmínkami ochrany osobních údajů
                </div>
            </div>
        </v-container>
    </div>
</template>

<script setup>
import { computed, inject, ref, watch } from 'vue';
import { router, usePage } from '@inertiajs/vue3';

const props = defineProps({
    show: Boolean,
    text: String,
    image: Object,
});

const user = computed(() => usePage().props.customer);
const route = inject('route');

const form = ref(null);

const email = ref('');
const readonly = ref(false);

watch(
    user,
    (val) => {
        if (val.email) {
            email.value = val.email;
            readonly.value = true;
        } else {
            email.value = email.value || '';
            readonly.value = false;
        }
    },
    { immediate: true }
);

const submit = () => {
    router.post(
        route('newsletter.form'),
        {
            email: email.value,
        },
        {
            preserveState: true,
            errorBag: 'newsletter',
            onError: () => {
                form.value.validate();
            },
            onSuccess: () => {
                form.value.resetValidation();
                canShow.value = false;
                close();
            },
        }
    );
};
</script>

<style scoped lang="scss">
@use 'vuetify/lib/styles/settings/variables' as *;

#newsletter-form {
    .v-input:first-child {
        :deep(.v-field.v-field--appended) {
            padding-inline-end: 0;
        }
    }

    .v-input:last-child {
        :deep(.v-selection-control) {
            justify-content: center;
        }
    }

    :deep(.v-input__details) {
        text-align: right;
    }

    :deep(.v-counter) {
        color: white;
    }
}
</style>
