<template>
    <div class="main_search" :class="mobile ? 'mobile px-5' : 'desktop justify-end'">
        <v-text-field
            :id="`searchField-${mobile}`"
            variant="plain"
            density="compact"
            :placeholder="$t('search.search')"
            rounded="xl"
            v-model="modelValue"
            :clearable="true"
            :hide-details="true"
            @update:modelValue="startSearching"
            :append-inner-icon="mdilMagnify"
            @click:append-inner="search"
            @keyup.enter="search"
            @click="keepMenuOpen(modelValue)"
        ></v-text-field>
        <v-menu
            v-if="show && modelValue && (hits.length || hits.notFound)"
            v-model="menuModel"
            :activator="`#searchField-${mobile}`"
            :open-on-click="!!modelValue"
            :close-on-content-click="false"
            location="bottom center"
            theme="light"
        >
            <v-card class="hints-card rounded-0 bg-grey-lighten-4" width="auto">
                <v-card-text :class="$vuetify.display.xs ? 'py-0' : ''">
                    <div v-if="hits.length">
                        <v-row class="scroll-area">
                            <v-col
                                v-for="(_hits, index) in hits"
                                :key="index"
                                cols="12"
                            >
                                <div v-if="_hits.hits" class="d-flex flex-column">
                                    <v-card-title
                                        class="flex-grow-1 flex-shrink-1 flex-basis-100"
                                        :class="$vuetify.display.xs ? 'px-0' : ''">
                                        {{ _hits.label }}
                                        ({{ _hits.hits.length }})
                                    </v-card-title>
                                    <v-card-text
                                        class="pt-0 flex-grow-1 flex-shrink-1 flex-basis-100"
                                        :class="$vuetify.display.xs ? 'px-0' : ''">
                                        <ul class="list-style-none">
                                            <li v-for="hit in _hits.hits" :key="`${hit.id}-${_hits.label}`"
                                                class="search-item">
                                                <v-hover v-slot="{isHovering, props}">
                                                    <Link :href="`${hit.url}?trackquery=${modelValue}`"
                                                          v-bind="props"
                                                          @click="resetSearch">
                                                        <v-card class="d-flex align-center elevation-0"
                                                                :class="{'bg-grey-lighten-4' : !isHovering, 'bg-grey-lighten-2' : isHovering}">
                                                            <v-card-text class="d-flex w-100 align-center">
                                                                <v-avatar size="40"
                                                                          :class="$vuetify.display.smAndDown ? 'sml' : ''">
                                                                    <v-img
                                                                        :src="`${hit.image}?width=40`"
                                                                        aspect-ratio="1"
                                                                        cover="cover"></v-img>
                                                                </v-avatar>
                                                                <div
                                                                    class="ml-3 flex-grow-1 c-hit__text"
                                                                    :class="{'text-grey': !isHovering, 'text-cis-accent-color' : isHovering, 'is-hovering': isHovering}"
                                                                    v-html="hit.name"
                                                                ></div>
                                                                <div
                                                                    v-if="hit.price"
                                                                    class="text-right text-cis-accent-color"
                                                                >
                                                                    <template v-if="hit.has_action_price">
                                                                        <div class="d-flex flex-column">
                                                                            <div>{{ hit.price }}</div>
                                                                            <div class="text-caption text-decoration-line-through">{{ hit.base_price }}</div>
                                                                        </div>
                                                                    </template>
                                                                    <template v-else>
                                                                        {{hit.price}}
                                                                    </template>
                                                                </div>
                                                            </v-card-text>
                                                        </v-card>
                                                    </Link>
                                                </v-hover>
                                            </li>
                                        </ul>
                                    </v-card-text>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-else-if="modelValue && !searching">{{ $t('search.nothing_was_found')}}</div>
                </v-card-text>
            </v-card>
        </v-menu>
    </div>
</template>
<script setup>

import { Link, router } from '@inertiajs/vue3';
import {useSearchStore} from "./store/search";
import {debounce} from "lodash-es";
import {storeToRefs} from "pinia";
import pinia from "../../plugins/pinia";
import {ref, inject} from "vue";
import { addEvent } from '../../gtm.js';
import { mdilMagnify } from '@mdi/light-js';

const props = defineProps({
    mobile: Boolean,
    show: Boolean
});
const emit = defineEmits(['on-search'])
const store = useSearchStore(pinia);
const {query: modelValue, hits} = storeToRefs(store);
const searching = ref(false);
const route = inject('route');
const menuModel = ref(!!props.modelValue);
let logTimeout = null;
const startSearching = debounce((query) => {
    clearTimeout(logTimeout);
    if(query) {
        logTimeout = setTimeout(() => {
            store.logQuery(query, '/log-search')
        }, 3500);
    }
    searching.value = true;
    store.search(query, '/search').then(() => {
        searching.value = false;
        menuModel.value = true;
        addEvent({
            event: 'search',
            ecommerce: {
                search_term: query,
            }
        })
    });
}, 250)

const search = () => {
    if(modelValue.value.trim() !== '') {
        router.visit(route('search.view', { 'query': modelValue.value }));
        emit('on-search');
        modelValue.value = '';
    }
}
const keepMenuOpen = (value) => {
    if (value) {
        menuModel.value = true;
    }
}
const resetSearch = () => {
    clearTimeout(logTimeout);
    modelValue.value = '';
}
</script>

<style>
.main_search.desktop path {
    fill: black !important;
}
</style>

<style scoped lang="scss">
@use 'vuetify/lib/styles/settings/colors' as *;
@use 'sass:map';

:deep(em) {
    font-weight: 700;
    color: map.get($grey, darken-2);
    font-style: normal;
}

.c-hit__text {
    &.is-hovering {
        :deep(em) {
            color: rgb(var(--v-theme-cis-accent-color));
        }
    }
}

ul {
    li + li {
        border-top: 1px solid map.get($grey, lighten-2);
    }
}

.is-hidden {
    :deep(.v-field__input) {
        visibility: hidden;
    }
}

.desktop {
    :deep(.v-input) {
        max-width: 100%;
        transition: max-width .3s ease;
        .v-icon {
            transition: color .3s ease;
        }

        .v-field {
            padding: 0 10px;
            color: black;
            background-color: rgb(var(--v-theme-surface-light));
        }

        &.is-hidden {
            max-width: 50px;

            .v-field {
                border-color: transparent;
            }
        }
    }
}

.main_search {
    flex: 1 1 auto;
    max-width: 550px;

    &.mobile, &.desktop {
        > :deep(.v-input) {
            .v-field__clearable, .v-field__append-inner {
                padding-top: 0;
            }

            padding: 4px 20px 4px 25px;
            border-radius: 999px;
            background-color: rgb(var(--v-theme-surface-light));

            .v-field__field {
                background-color: rgb(var(--v-theme-surface));
            }

            .v-field__input {
                background-color: rgb(var(--v-theme-surface-light));
                font-size: .825rem;
            }

            .v-field {
                padding-left: 0;
                color: black;

                .v-field__outline {
                    > * {
                        transition: border-color .3s ease;
                    }
                }

                &--focused {
                    .v-field__outline {
                        > * {
                            border-color: rgb(var(--v-theme-cis-accent-color));
                        }
                    }
                }
            }

            .v-field__clearable {
                margin: 0;

                .v-icon {
                    margin-left: 4px;
                }
            }

            .v-field__append-inner {
                .v-icon {
                    transform: translate3d(25%, 0, 0);
                }
            }
        }
    }

    > :deep(.v-input) {
        .v-field__clearable, .v-field__append-inner {
            padding-top: var(--v-field-input-padding-bottom);
            align-self: center;
        }
        .v-field__input {
            padding-top: calc(var(--v-input-chips-margin-bottom) + var(--v-field-input-padding-bottom));
        }
    }
}
.mobile {
    > :deep(.v-input) {
        .v-field__input {
            padding-top: var(--v-field-input-padding-top) !important;
        }
    }
}
.hints-card {
    transform: translate3d(0, 10px, 0);
    //background: rgba(255, 255, 255, .7) !important;

    .v-avatar {
        flex: 0 0 auto;
    }

    > .v-card-text .scroll-area {
        max-height: 70dvh;
        overflow-y: auto;
    }
}
</style>
