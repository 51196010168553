import {defineStore} from "pinia";
import { ref, watch } from 'vue';
import { usePage } from '@inertiajs/vue3';

export const useComparatorStore = defineStore('comparator', () => {
    const data = ref(usePage().props.comparator);
    watch(() => usePage().props.comparator, (val) => {
        data.value = val;
    }, {deep: true})
    return {data};
});
