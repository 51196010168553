import '../../css/app.scss';
import {createSSRApp, h} from 'vue'
import {createInertiaApp, router} from '@inertiajs/vue3'
import vuetify from "../plugins/vuetify.js";
import pinia from "../plugins/pinia";
import basicLayout from "./layouts/basicLayout.vue";
import Notifications from '@kyvg/vue3-notification';
import {ZiggyVue} from "ziggy-js";
import {Ziggy as ziggyRoutes} from "../ziggy";
import {partytownSnippet} from '@qwik.dev/partytown/integration';
import { I18n } from 'laravel-vue-i18n';
import axios from 'axios';

const snippetText = partytownSnippet();
if (typeof document !== "undefined") {
    const partytownEl = document.getElementById('partytown');
    if (partytownEl) {
        partytownEl.innerText = snippetText;
    }
}

let stale = false;

window.addEventListener('popstate', () => {
    stale = true;
    document.getElementById('root')?.classList.remove('gallery-open');
});

router.on('navigate', (event) => {
    const page = event.detail.page;
    if (stale) {
        router.get(page.url, {}, {replace: true, preserveState: false, preserveScroll: true});
    }
    stale = false;
});

let loaded = false;

const lang = navigator.language.split('-')[0];

const I18nOptions = {
    shared: true,
    lang: lang,
    fallbackLang: lang,
    resolve: async (lang) => {
        if (lang && !loaded) {
            loaded = (await axios.get('/api/translates')).data;
        }
        return { default: loaded };
    }
}

const i18n = I18n.getSharedInstance(I18nOptions, true)

const cisI18nVue = {
    install(app, options = {}) {
        options = {...options}
        app.config.globalProperties.$t = (key, replacements) => i18n.trans(key, replacements);
        app.config.globalProperties.$tChoice = (key, number, replacements) => i18n.transChoice(key, number, replacements);
        app.provide('i18n', i18n);
    }
};

createInertiaApp({
    progress: {
        color: '#0d6efd'
    },
    title: title => `${title} - ${import.meta.env.VITE_APP_NAME}`,
    resolve: async name => {
        let page;
        if (name.startsWith('@Module::')) {
            let parts = name.substring(9);
            parts = parts.split('-');
            page = await import(`../modules/${parts[0]}/frontend/pages/${parts[1]}.vue`);
        } else if(name.startsWith('errors')) {
            let component = name.substring(7);
            page = await import(`./pages/errors/${component}.vue`);
        } else if (name.startsWith('@ExtModule::')) {
            let parts = name.substring(12);
            parts = parts.split('-');
            page = await import(`../../../Modules/${parts[0]}/Resources/js/frontend/pages/${parts[1]}.vue`);
        } else {
            page = await import(`./pages/${name}.vue`);
        }
        if (page.default.layout === undefined) {
            page.default.layout = basicLayout;
        }
        return page;
    },
    setup({el, App, props, plugin}) {
        createSSRApp({render: () => h(App, props)})
            .use(plugin)
            .use(vuetify(props.initialPage.props.accentColor))
            .use(Notifications)
            .use(ZiggyVue, ziggyRoutes)
            .use(pinia)
            .use(cisI18nVue)
            .mount(el)
    },
})
